<template>
  <div>
    <div class="page-shop shopping-cart">
     
      <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
      <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
    </div>

      <div class="row" v-if="!isLoading">
        <div class="col-md-12" v-if="subscription">

          <div class="container">

            <div class="card border-0 rounded-0 mb-4 sidebar-checkout">
              <div class="card-header d-flex bg-transparent px-0 pt-0 pb-2 mb-3">
                <div class="text-gray text-uppercase">Product</div>
                <div class="text-gray text-uppercase ml-auto">Total</div>
              </div>
              <div class="card-body p-0">
                <div class="border-bottom pb-1 mb-5">

                  <div class="font-size-md mb-0 row lh-15">
                    <div class="col-5">
                      <label class="text-primary mb-0">{{subscription.title}} / {{subscription.price}} {{subscription.currency}}</label>
                      <br>
                      <span>
                        {{subscription.description}}
                      </span>
                    </div>
                    <div class="col-4">
                      <div class="form-group  d-flex align-items-center mb-0" style="width: 100%;!important">
                        <div class="row">
                          <div class="col-8">
                                  <div class="select-custom bg-white">
                                      <select style="width: 100%;!important" v-on:change="getSubcriptionPeriod" class="form-control form-control-sm ">
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                    </select>
                                </div>
                          </div>
                           <div class="col-4">
                             <input v-model="details.subscriptionQuantity" 
                          class="form-control form-control-sm text-center" v-on:keyup="updateCart" v-on:click="updateCart" style="width: 100%;!important" type="number">
                           </div>

                        </div>
                       
                      </div>
                    </div>
                    <div class="col-3 text-right">
                      <span class="text-dark ml-auto"> {{details.total}} {{subscription.currency}}</span>
                    </div>
                  </div>


                </div>
                <div class="d-flex align-items-center border-bottom pb-1 mb-2">
                  <label class="text-dark font-size-md mb-0">Total</label>
                  <span class="text-primary ml-auto font-size-h5 font-weight-semibold">
                    {{details.total}} {{subscription.currency}}
                  </span>
                </div>


              </div>
            </div>

            <flutterwave-pay-button v-if="this.currentBusiness"
                :tx_ref="generateReference()"
                :amount='details.total'
                :currency='subscription.currency'
                payment_options="card,ussd"
                class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm"
              
                :customer="{ name: this.currentBusiness.businessName,
                email: this.currentBusiness.businessEmail, 
                phone_number: this.currentBusiness.partnerAddresses.telephone }"
                :customizations="{  title: 'GridBusiness' ,
                description: 'Simple digital address...'  ,
                logo : 'https://gridbusiness.azurewebsites.net/images/logo.png' }"
                :callback="makePaymentCallback"
                :onclose="closedPaymentModal"> Click To Pay </flutterwave-pay-button>

           
          </div>


        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
  import {
    MoonLoader
  } from "@saeris/vue-spinners";
  export default {
    name: "PaymentMethod",
    props: ["subscription", "partnerId", "reset","redirectUrlName"],
    data: function () {
      return {
        canEnterOtp: false,
        link: '',
        url:'',
        currentSubacription:null,
         isLoading:false,
        details: {
          subscriptionPeriod: 'month',
          subscriptionQuantity: 1,
          total: 0
        },
          paymentDetails: {
                    paymentMethod: 'mobile-money',
                    partnerId: null,
                    subscriptionId: null,
                     subscriptionPeriod: 'month',
                    subscriptionQuantity: 1,
                    paymentGatway:'Flutterwave',
                    total: 0
                },

      };
    },
    components: {
  MoonLoader,
    },
    computed: {
      ...mapGetters("payment", ['payment']),
      ...mapGetters("setting", ["setting"]),
      ...mapGetters("business", ['currentBusiness']),
      
    },
    watch: {
      reset() {
        if (this.reset) {
          this.canEnterOtp = false;
          this.link = '';
        };
      },
      subscription: {
          handler: 'getSubscription'
        }
      
    },
     mounted: async function () {
       this.url=process.env.VUE_APP_API_URL;
        this.getSetting();
         this.getPartnerBusiness(this.partnerId);
    await this.getSubscription(this.subscription);
   
  },   updated(){
        if(this.setting){
          window.localStorage.setItem('fwpk',this.setting.flutterwave_public_key);
        }
  },
    methods: {
       ...mapActions("payment", ["sendMomoPayRequest"]),
      ...mapActions("setting", ["getSetting"]),
       ...mapActions("business", ["getPartnerBusiness"]),
       async getSubscription(el) {
          this.currentSubacription=el;
           await this.loadCart();
    },
     makePaymentCallback(response) {
      console.log("Payment callback", response);

          if(response.status =='successful'){

                this.paymentDetails.partnerId = this.partnerId;
                this.paymentDetails.subscriptionId = this.subscription.id;
                this.paymentDetails.subscriptionPeriod=this.details.subscriptionPeriod;
                this.paymentDetails.subscriptionQuantity=this.details.subscriptionQuantity;
                this.paymentDetails.total=this.details.total;
                this.paymentDetails.transaction_id=response.transaction_id;
                   

                this.isLoading = true;
               
                this.sendMomoPayRequest(this.paymentDetails).then(() => {
                    this.isLoading = false;
                    this.paymentDetails = {
                        paymentMethod: 'mobile-money',
                        partnerId: null,
                        subscriptionId: null,
                        subscriptionPeriod:null,
                        subscriptionQuantity:0,
                        total:0
                    };

                       this.$notify({
                                group: "app",
                                type: "success",
                                title: "Done",
                                text:'Payment made successfully',
                                duration: 10000,
                                speed: 1000,
                                });
                                
                                setTimeout(()=>{
                                     return  window.location.href="/mySubscription/"+this.partnerId;
                                },1000);
                    

                }).catch(()=>this.isLoading = false);
      }else{
                           this.$notify({
                                group: "app",
                                type: "error",
                                title: "Done",
                                text:'Payment failed, try again!',
                                duration: 10000,
                                speed: 1000,
                                });
                                
                                setTimeout(()=>{
                                     return  window.location.reload();;
                                },1000);
      }
    },
    closedPaymentModal() {
      console.log('payment modal is closed');
    },
    generateReference(){
      let date = new Date()
      return date.getTime().toString();
    },
      loadCart(){
         if (this.currentSubacription) {
          if (this.details.subscriptionPeriod == 'month') {
            this.details.total = this.details.subscriptionQuantity * this.currentSubacription.price;
          } else if (this.details.subscriptionPeriod == 'year') {
            this.details.total = 12 * this.details.subscriptionQuantity * this.currentSubacription.price;
          }
      }
      },
      
   updateCart: function (event) {
        let qty = event.target.value;
        if(qty == 0 || qty < 0){
          return;
        }
        this.details.subscriptionQuantity=qty;

         if (this.currentSubacription) {
          if (this.details.subscriptionPeriod == 'month') {
            this.details.total = this.details.subscriptionQuantity * this.currentSubacription.price;
          } else if (this.details.subscriptionPeriod == 'year') {
            this.details.total = 12 * this.details.subscriptionQuantity * this.currentSubacription.price;
          }
      }

   },
   getSubcriptionPeriod:function(event){
  let period = event.target.value;
        this.details.subscriptionPeriod=period;

         if (this.currentSubacription) {
          if (this.details.subscriptionPeriod == 'month') {
            this.details.total = this.details.subscriptionQuantity * this.currentSubacription.price;
          } else if (this.details.subscriptionPeriod == 'year') {
            this.details.total = 12 * this.details.subscriptionQuantity * this.currentSubacription.price;
          }
      }
   },
      paymentCompleted: function (response) {
        this.$emit('completed', response)
      },
      resizeModal(modal) {
        this.$emit('resize', modal);
      },

      getUrl(url) {
        if (url.show) {
          this.canEnterOtp = true;
        }
        if (!url.show) {
          this.canEnterOtp = false;
        }
        this.link = url.link;
      }

    },
  };
</script>