<template>
  <div>
    <div class="page-pricing-plan">
      <!-- #page-title end -->
      <!-- #wrapper-content start -->
      <div class="mt-2">
        <div class="container">
          <div id="pricing-table" class="section-pricing-table">
            <div
              v-if="isLoading"
              class="card-deck justify-content-center align-items-center"
            >
              <MoonLoader
                :loading="true"
                :color="'#36beb0'"
                :size="60"
                :sizeUnit="'px'"
              />
            </div>
            <div class="card-deck" v-else>
              <div
                v-for="subscripionPlan in subscripionPlans"
                :key="subscripionPlan.id"
                class="pricing-table card rounded-0" 
                :class="
                  subscripionPlan.isDefault ? 'bg-primary text-white' : ''
                "
              >
                <div class="card-header bg-transparent border-0 p-0">
                  <table>
                    <tr>
                      <td>
                        <div
                          class="
                            font-weight-semibold font-size-md
                            mb-3
                            text-uppercase
                          "
                        >
                          {{ subscripionPlan.title }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="mb-5">
                          <span
                            class="price"
                            :class="
                              subscripionPlan.isDefault
                                ? 'bg-primary text-white'
                                : 'text-primary'
                            "
                            >{{ subscripionPlan.currency
                            }}{{ subscripionPlan.price }}
                          </span>
                          <span>/</span>
                          <span>Mo</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="mb-6">{{ subscripionPlan.description }}</p>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="card-body px-0 pt-5 pb-7">
                  <ul
                    class="
                      features
                      list-group list-group-flush list-group-borderless
                    "
                  >
                    <li
                      class="list-group-item bg-transparent p-0 mb-1"
                      v-if="subscripionPlan.isDefault"
                    >
                      <span class="font-size-md d-inline-block mr-3"
                        ><i class="fal fa-check"></i
                      ></span>
                      <span>Credit: {{ subscripionPlan.credit }}</span>
                    </li>

                    <li class="list-group-item bg-transparent p-0 mb-1" v-else>
                      <span class="text-green font-size-md d-inline-block mr-3"
                        ><i class="fal fa-check"></i
                      ></span>
                      <span class="text-dark"
                        >Credit: {{ subscripionPlan.credit }}</span
                      >
                    </li>
                  </ul>
                </div>

                <div class="card-footer bg-transparent border-0 p-0 mt-auto">
                  <button
                    @click="createFreePartnerSubscriptions(subscripionPlan.id)"
                    :disabled="isLoading ? '' : isLoading"
                    v-if="subscripionPlan.isDefault"
                    class="
                      btn btn-white
                      lh-lg
                      btn-block
                      font-weight-bold
                      rounded-0
                    "
                  >
                    Register Now
                  </button>

                  <button
                    @click="openPaymentModal(subscripionPlan)"
                    class="
                      btn btn-primary btn-block
                      lh-lg
                      font-weight-bold
                      rounded-0
                    "
                    v-else
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- #pricing-tableEND -->
        </div>
      </div>
      <!-- #wrapper-content end -->
    </div>

    <DynamicModal
      v-if="$route.params.partnerId"
      v-show="isFreeSubptionModalVisible"
      @close="closeFreeSubptionModal"
      v-bind:modal-size="'modal-sm'"
      v-bind:hide-close-btn="hideCloseBtnOnFreeSubscptionModal"
      v-bind:hide-header="true"
      v-bind:hide-footer="hideModalFooter"
    >
      <template v-slot:body>
        <div>
          <div class="card-deck justify-content-center align-items-center">
            <MoonLoader
              :loading="true"
              :color="'#36beb0'"
              :size="60"
              :sizeUnit="'px'"
            />
          </div>
        </div>
      </template>
    </DynamicModal>

    <DynamicModal
      v-if="$route.params.partnerId"
      v-show="isPaymentModalVisible"
      @close="closePaymentModal"
      v-bind:modal-size="modalSize"
      v-bind:hide-footer="true"
    >
      <template v-slot:header> Payment </template>
      <template v-slot:body>
        <div>
          <PaymentMethod
		  	v-bind:redirect-url-name="redirectUrlName"
            v-bind:reset="resetPaymentModal"
            v-bind:partner-id="$route.params.partnerId"
            v-bind:subscription="subscription"
            v-on:completed="paymentCompleted"
            v-on:resize="resizeModal"
          />
        </div>
      </template>
    </DynamicModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DynamicModal from "../Common/Modal/DynamicModal";
import PaymentMethod from "../Common/Payment/PaymentMethod";
import { MoonLoader } from "@saeris/vue-spinners";

export default {
  name: "ChooseNewSubscription",
   props: ["hideDefaultSubscription","redirectUrlName"],
  data: function () {
    return {
      isLoading: false,
      modalSize: "modal-lg",
      isFreeSubptionModalVisible: false,
      hideCloseBtnOnFreeSubscptionModal: true,
      hideModalFooter: true,
      isPaymentModalVisible: false,
      subscription: null,
      resetPaymentModal: false,
    };
  },
  components: {
    DynamicModal,
    MoonLoader,
    PaymentMethod,
  },
  computed: {
    ...mapGetters("subscription", ["subscripionPlans"]),
    ...mapGetters(["errors", "isSuccess"]),
  },

  mounted() {
    this.isLoading = true;
    this.getSubscripionPlans(this.$route.params.partnerId).then(() => {
      this.isLoading = false;
    });
    this.$store.commit("setErrors", {});
    this.$store.commit("setIsSuccess", false);

    if (!this.$route.params.partnerId) {
      this.$router.push({
        name: "Home",
      }).catch(() => {});
    }
  },

  methods: {
    ...mapActions("subscription", [
      "getSubscripionPlans",
      "sendFreePartnerSubscriptions",
    ]),
    resizeModal(modal) {
      this.modalSize = modal;
    },
    paymentCompleted: function () {
      this.$router.push({
        name: "MyBusiness",
        params: {
          partnerId: this.$route.params.partnerId,
        },
      }).catch(() => {});
    },
    openSubptionModal() {
      this.isFreeSubptionModalVisible = true;
    },
    closeFreeSubptionModal() {
      this.isFreeSubptionModalVisible = false;
    },
    closePaymentModal() {
      this.isPaymentModalVisible = false;
      this.resetPaymentModal = true;
    },
    openPaymentModal(subscription) {
      this.modalSize = "modal-lg";
      this.isPaymentModalVisible = true;
      this.subscription = subscription;
      this.resetPaymentModal = false;
    },
    createFreePartnerSubscriptions: function (id) {
      let details = {
        subscriptionId: id,
        partnerId: this.$route.params.partnerId,
      };
      this.hideCloseBtnOnFreeSubscptionModal = true;
      this.hideModalFooter = true;
      this.openSubptionModal();

      this.sendFreePartnerSubscriptions(details).then(async () => {
      
        if (this.isSuccess) {
          this.$notify({
            group: "app",
            type: "success",
            title: "Done",
            text: "Subscription created successfully",
            duration: 10000,
            speed: 1000,
          });
          this.closeFreeSubptionModal();
            return  window.location.href="/myBusiness/"+this.$route.params.partnerId;
        }
      });
    },
  },
};
</script>